<template>
    <div class="about">
      <h1>About this app</h1>
      <p>Welcome to Purple Dart! This app is a dart scoring application. It allows you to:</p>
      <ul>
        <li>Throw darts and record the score</li>
        <ul>
          <li>3 darts per round</li>
          <li>Throw as many rounds as you want</li>
        </ul>
        <li>View statistics about your dart throwing:</li>
        <ul>
          <li>Score - Tally per round (also best 2 darts chart available)</li>
          <li>Spread - The distance between darts (lower is better)</li>
          <li>Centroid - Where is the average of your throws?</li>
          <li>Heatmap - Where do you tend to hit?</li>
        </ul>
      </ul>
      <p>To report bugs or feature requests, reach out to: contact@purpledart.app</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AboutPage'
  }
  </script>