<template>
  <div>
    <div id="chartsBox" v-if="isLoggedIn">
      <h1>Stats</h1>
      <p>Select a chart and number of rounds to display</p>
      <select v-model="selectedChart">
        <option value="ScoresChart">Scores</option>
        <option value="SpreadChart">Spread</option>
        <option value="Scores2DartsChart">Scores (best 2 darts)</option>
        <option value="CentroidChart">Centroid</option>
        <option value="HeatMapChart">Heatmap</option>
      </select> last
      <select v-model="numRounds">
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="all">All</option>
      </select> rounds.
      <component :is="ChartComponent" :numRounds="numRounds" :userId="userId.value" :key="userId.value + selectedChart + numRounds" />
    </div>
    <div v-else>
      You have to be logged in to view stats.
    </div>
  </div>
</template>

<script>
import { ref, onMounted, inject, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { getAuth } from 'firebase/auth';
import ScoresChart from './charts/ScoresChart.vue';
import SpreadChart from './charts/SpreadChart.vue';
import Scores2DartsChart from './charts/Scores2DartsChart.vue';
import CentroidChart from './charts/CentroidChart.vue';
import HeatMapChart from './charts/HeatMapChart.vue';

export default {
  components: {
    ScoresChart,
    SpreadChart,
    Scores2DartsChart,
    CentroidChart,
    HeatMapChart
  },
  setup() {
    const isLoggedIn = ref(false);
    const userId = inject('userId'); // Inject userId from the provider
    const isLoading = inject('isLoading'); // Inject isLoading from the provider
    const selectedChart = ref('ScoresChart'); // Set ScoresChart as the default selected chart
    const numRounds = ref('10'); // Set 10 as the default number of rounds

    onMounted(() => {
      const auth = getAuth();
      isLoggedIn.value = auth.currentUser !== null;

      const chart = router.currentRoute.value.query.chart || 'ScoresChart';
      selectedChart.value = chart;
    });

    const router = useRouter();

    watch(selectedChart, (newChart) => {
      router.push({ query: { ...router.currentRoute.value.query, chart: newChart } });
    });

    const ChartComponent = computed(() => {
    if (!isLoading.value && userId.value) {
      switch (selectedChart.value) {
        case 'ScoresChart':
          return ScoresChart;
        case 'SpreadChart':
          return SpreadChart;
        case 'Scores2DartsChart':
          return Scores2DartsChart;
        case 'CentroidChart':
          return CentroidChart;
        case 'HeatMapChart':
          return HeatMapChart;
        default:
          return null;
      }
    }
    return null;
  });

    return {
      isLoggedIn,
      selectedChart,
      numRounds,
      userId,
      isLoading,
      ChartComponent
    };
  }
}
</script>