<template>
  <div>
    <h3>Scores</h3>
    <p>Mean: {{meanHits}}</p>
    <p>Median: {{medianHits}}</p>
    <canvas class="statChart" ref="scoreChartRef"></canvas>
    <!-- <button @click="toggleTrendline">Toggle Trendline</button> -->
    <CustomTooltip :show="showTooltip" :data="tooltipData" @close="showTooltip = false" />
    <table>
      <thead>
        <tr>
          <th class="table-cell">Round in chart</th>
          <th class="table-cell">Date</th>
          <th class="table-cell">Total Score</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(round, index) in roundsData" :key="index">
          <td class="table-cell">{{ index + 1 }}</td>
          <td class="table-cell">{{ formatDate(round.time) }}</td>
          <td class="table-cell">{{ round.totalScore }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { Chart, LineController, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';
import CustomTooltip from '../CustomTooltip.vue';
import annotationPlugin from 'chartjs-plugin-annotation';
import { getRoundsData, mean, median, formatDate } from '../../stats.js';
import { ref, onMounted, inject} from 'vue';

Chart.register(LineController, CategoryScale, LinearScale, PointElement, LineElement, annotationPlugin);

export default {
  components: {
    CustomTooltip
  },
  props: ['numRounds'],
  setup(props) {

    const userId = inject('userId'); 
    const isLoading = inject('isLoading'); 
    const scoreChartRef = ref(null);
    const scoreChart = ref(null);
    const showTooltip = ref(false);
    const tooltipData = ref({});
    const roundsData = ref([]);
    const numRounds = ref(props.numRounds);
    const meanHits = ref(0);
    const medianHits = ref(0);

    const chartClickHandler = (evt, activeElements) => {
      if (activeElements.length > 0) {
        const activeElement = activeElements[0];
        const datasetIndex = activeElement.datasetIndex;
        const index = activeElement.index;
        const dataset = scoreChart.value.data.datasets[datasetIndex];
        if (dataset && dataset.data) {
          const tooltipWidth = 320;
          const tooltipHeight = 150;
          let x = evt.x + 50;
          if (x + tooltipWidth > window.innerWidth) {
            x = window.innerWidth - tooltipWidth;
          }
          const y = evt.y + tooltipHeight;
          tooltipData.value = {
            ...roundsData.value[index],
            x,
            y,
            index,
            roundId: roundsData.value[index].docRef
          };
          showTooltip.value = true;
        } 
        else {
          showTooltip.value = false;
        }
      } else {
        showTooltip.value = false;
      }
    };

    async function renderChart() {
      if (scoreChartRef.value && userId.value) {
        if (scoreChart.value) {
          scoreChart.value.destroy();
          scoreChart.value = null;
        }

        const ctx = scoreChartRef.value.getContext('2d');
        numRounds.value = numRounds.value || 10;

        roundsData.value = await getRoundsData(userId.value, numRounds.value)

        // Calculate mean and median
        meanHits.value = mean(roundsData.value, 'totalScore');
        medianHits.value = median(roundsData.value, 'totalScore');

        // Calculate trendline data
        const xValues = roundsData.value.map((round, index) => index);
        const yValues = roundsData.value.map(round => round.totalScore);

        const xMean = xValues.reduce((a, b) => a + b, 0) / xValues.length;
        const yMean = yValues.reduce((a, b) => a + b, 0) / yValues.length;

        const slope = xValues.reduce((sum, x, i) => sum + (x - xMean) * (yValues[i] - yMean), 0)
          / xValues.reduce((sum, x) => sum + Math.pow(x - xMean, 2), 0);

        const intercept = yMean - slope * xMean;

        scoreChart.value = new Chart(ctx, {
            type: 'line',
            data: {
              labels: roundsData.value.map((_, i) => i + 1),
              datasets: [{
                label: 'Total Score',
                data: roundsData.value.map(round => round.totalScore),
                fill: false,
                borderColor: 'purple',
                tension: 0.1
              }]
            },
            options: {
              interaction: {
            mode: 'nearest',
            intersect: false,
            axis: 'x'
            },
            scales: {
              x: {
                display: true,
                title: {
                  display: true,
                  text: 'Rounds'
                }
              },
              y: {
                display: true,
                title: {
                  display: true,
                  text: 'Points'
                },
                min:0,
                max:180
              }
            },
            onClick: chartClickHandler,
            plugins: {
              annotation: {
                annotations: {
                  trendline: {
                    type: 'line',
                    yMin: intercept,
                    yMax: intercept + slope * (xValues.length - 1),
                    borderColor: 'DeepPink',
                    borderWidth: 2,
                    borderDash: [5, 5],
                  },
                },
              },
            }
          }
        });
      }
    }

    onMounted(async () => {
      renderChart();
    });

    return {
      scoreChartRef,
      scoreChart,
      showTooltip,
      tooltipData,
      roundsData,
      meanHits,
      medianHits,
      userId: userId.value,
      isLoading: isLoading.value,
      formatDate,
      chartClickHandler,
      renderChart
    };
  }
}
</script>