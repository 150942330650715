<template>
  <div id="login-box">
    <div v-if="currentSection === 'login'">
      <h1>Login</h1>
      <input v-model="email" type="email" placeholder="Email">
      <input v-model="password" type="password" placeholder="Password">
      <button class="login-button" @click="login">Login</button>
      <button class="login-link" @click="currentSection = 'forgotPassword'">Forgot password</button>
      <button class="login-link" @click="currentSection = 'register'">New user? Register here</button>
      <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
      <p class="error-message-placeholder" v-else>&nbsp;</p>
    </div>

    <div v-else-if="currentSection === 'forgotPassword'">
      <h1>Forgot Password</h1>
      <input v-model="forgotPasswordEmail" type="email" placeholder="Email">
      <button class="login-button" @click="resetPassword">Reset Password</button>
      <button class="login-link" @click="currentSection = 'login'">Back to Login</button>
      <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
      <p class="error-message-placeholder" v-else>&nbsp;</p>
    </div>

    <div v-else-if="currentSection === 'register'">
      <h1>Register</h1>
      <input v-model="registerEmail" type="email" placeholder="Email">
      <input v-model="registerPassword" type="password" placeholder="Password">
      <button class="login-button" @click="register">Register</button>
      <button class="login-link" @click="currentSection = 'login'">Back to Login</button>
      <p class="error-message" v-if="registerErrorMessage">{{ registerErrorMessage }}</p>
      <p class="error-message-placeholder" v-else>&nbsp;</p>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const currentSection = ref('login');
    const email = ref('');
    const password = ref('');
    const errorMessage = ref('');
    const registerEmail = ref('');
    const registerPassword = ref('');
    const registerErrorMessage = ref('');
    const showRegisterForm = ref(false);
    const router = useRouter();
    const errorMessages = {
      'auth/invalid-credential': 'Wrong email and/or password.',
      'auth/invalid-email': 'The email address is badly formatted.',
      'auth/user-disabled': 'This user account has been disabled.',
      'auth/user-not-found': 'No user found with this email.',
      'auth/wrong-password': 'The password is incorrect.',
    };
    const registerErrorMessages = {
      'auth/email-already-in-use': 'The email address is already in use by another account.',
      'auth/invalid-email': 'The email address is badly formatted.',
      'auth/operation-not-allowed': 'Email/password accounts are not enabled.',
      'auth/weak-password': 'The password is too weak.',
    };
    const resetPasswordErrorMessages = {
      'auth/invalid-email': 'The email address is badly formatted.',
      'auth/user-not-found': 'No user found with this email.',
      'auth/operation-not-allowed': 'Password reset is not enabled for this project.',
    };

    const login = async () => {
      const auth = getAuth();
      try {
        await signInWithEmailAndPassword(auth, email.value, password.value);
        router.push('/');
      } catch (error) {
        errorMessage.value = errorMessages[error.code] || 'An unknown error occurred.';
      }
    };

    const register = async () => {
      const auth = getAuth();
      try {
        await createUserWithEmailAndPassword(auth, registerEmail.value, registerPassword.value);
        router.push('/');
      } catch (error) {
        registerErrorMessage.value = registerErrorMessages[error.code] || 'An unknown error occurred.';
      }
    };

    const resetPassword = async () => {
      const auth = getAuth();
      try {
        await sendPasswordResetEmail(auth, email.value);
        alert('Password reset email sent!');
      } catch (error) {
        alert(resetPasswordErrorMessages[error.code] || 'An unknown error occurred.');
      }
    };

    return {
      currentSection,
      email,
      password,
      errorMessage,
      registerEmail,
      registerPassword,
      registerErrorMessage,
      showRegisterForm,
      resetPassword,
      login,
      register
    };
  }
}
</script>