import Victor from 'victor'; 

const pieceDegrees = 360 / 20;

const geometry = {
    canvasSize: null,
    scaleRatio: null,
    center: null,
    radius: null,
    centerVector: null,
    zeroDegreesVector: null,
    lengthVector: null,
    normVector: null,
    doubleTopMax: null,
    doubleTopMin: null,
    tripleMax: null,
    tripleMin: null,
    twentyFiveTopMin: null,
    bullTopMin: null
  };

function initGeometry(ctx){
    geometry.canvasSize = ctx.canvas.width;
    geometry.scaleRatio = geometry.canvasSize / 360;
    geometry.center = { x: geometry.canvasSize / 2, y: geometry.canvasSize / 2 };
    geometry.radius = geometry.canvasSize / 2;
    geometry.centerVector = new Victor(geometry.center.x, geometry.center.y);
    geometry.zeroDegreesVector = new Victor(geometry.center.x, geometry.center.y - geometry.radius);
    geometry.lengthVector = geometry.zeroDegreesVector.clone().subtract(geometry.centerVector);
    geometry.normVector = new Victor(0, 0);
    geometry.doubleTopMax = geometry.radius * 0.8;
    geometry.doubleTopMin = geometry.radius * 0.75;
    geometry.tripleMax = geometry.radius * 0.45;
    geometry.tripleMin = geometry.radius * 0.40;
    geometry.twentyFiveTopMin = geometry.radius * 0.08;
    geometry.bullTopMin = geometry.radius * 0.04;
}

export function drawDartBoard(ctx, hits) {

    clearCanvas(ctx);
    initGeometry(ctx);

    let count = 0;
    let pos = 0;

    drawCircle(geometry.radius, 'silver', 'black');

    for (let counter = -9; counter < (360 - 9); counter += pieceDegrees) {

        buildRing(geometry.doubleTopMin, geometry.doubleTopMax, counter, counter + pieceDegrees, count++ % 2 ? '#03ff03' : 'red');
        buildRing(geometry.tripleMin, geometry.tripleMax, counter, counter + pieceDegrees, count % 2 ? 'red' : '#03ff03');
        buildRing(geometry.doubleTopMin, geometry.tripleMax, counter, counter + pieceDegrees, count % 2 ? 'black' : 'white');
        buildRing(geometry.tripleMin, geometry.twentyFiveTopMin, counter, counter + pieceDegrees, count % 2 ? 'black' : 'white');

        printLabel(counter, pos++);
    }

    drawRadials();

    drawCircle(geometry.doubleTopMax, 'silver');
    drawCircle(geometry.doubleTopMin, 'silver');
    drawCircle(geometry.tripleMax, 'silver');
    drawCircle(geometry.tripleMin, 'silver');
    drawCircle(geometry.twentyFiveTopMin, 'silver', 'green');
    drawCircle(geometry.bullTopMin, 'silver', 'red');

    hits.forEach((dart) => indicateHit(ctx, dart));

    function clearCanvas(ctx) {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    }

    function buildRing(min, max, startDeg, endDeg, colour){
        ctx.beginPath();
        ctx.fillStyle = colour;
        ctx.strokeStyle = colour;
  
        for (let counter = startDeg; counter <= endDeg; counter += 1) {
          const doubleTopMaxPoint = geometry.centerVector
            .clone()
            .add(
              geometry.lengthVector
                .clone()
                .norm()
                .rotateDeg(counter)
                .multiply(new Victor(max, max))
            );
  
          ctx.lineTo(doubleTopMaxPoint.x, doubleTopMaxPoint.y);
        }
  
        for (let counter = endDeg; counter >= startDeg; counter -= 1) {
          const doubleTopMinPoint = geometry.centerVector
            .clone()
            .add(
              geometry.lengthVector
                .clone()
                .norm()
                .rotateDeg(counter)
                .multiply(new Victor(min, min))
            );
  
          ctx.lineTo(doubleTopMinPoint.x, doubleTopMinPoint.y);
        }
  
        ctx.fill();
        ctx.stroke();
      }

    function drawCircle(radius, colour, fillColour) {
        ctx.beginPath();
        ctx.arc(geometry.center.x, geometry.center.y, radius, 0, 2 * Math.PI, false);
        ctx.lineWidth = 1;
        ctx.strokeStyle = colour;

        if (fillColour) {
            ctx.fillStyle = fillColour;
            ctx.fill();
        }

        ctx.stroke();
    }

    function drawRadial(point) {
        ctx.beginPath();
        ctx.moveTo(geometry.center.x, geometry.center.y);
        ctx.lineTo(point.x, point.y);
        ctx.stroke();
    }
        

    function drawRadials() {
        ctx.lineWidth = 2;
        ctx.strokeStyle = 'white';

        for (let counter = 9; counter < 360; counter += pieceDegrees) {
            const x = geometry.centerVector
            .clone()
            .add(
                geometry.lengthVector
                .clone()
                .norm()
                .rotateDeg(counter)
                .multiply(new Victor(geometry.doubleTopMax, geometry.doubleTopMax))
            );

            drawRadial(x);
        }
    }

    function drawLabel(text, p1, p2, alignment, padding, rotate) {
        alignment = alignment || 'center';
        padding = padding || 0;
        rotate = rotate || false;

        const dx = p2.x - p1.x;
        const dy = p2.y - p1.y;

        let p, pad;

        if (alignment === 'center') {
            p = p1;
            pad = 1 / 2;
        } else {
            const left = alignment === 'left';
            p = left ? p1 : p2;
            pad = (padding / Math.sqrt(dx * dx + dy * dy)) * (left ? 1 : -1);
        }

        ctx.save();
        ctx.strokeStyle = 'white';
        ctx.fillStyle = 'white';
        ctx.font = geometry.scaleRatio * 20 + 'px Arial';
        ctx.textAlign = alignment;
        ctx.translate(p.x + dx * pad, p.y + dy * pad);

        if (rotate) {
            ctx.rotate(Math.atan2(dy, dx));
        }

        ctx.fillText(text, 0, 0);
        ctx.restore();
    }

    function printLabel(counter, pos){

        const labels = [
          20, 1, 18, 4, 13, 6, 10, 15, 2, 17, 3, 19, 7, 16, 8, 11, 14, 9, 12, 5
        ];
  
        const firstWireOfPeice = counter + 9;
        const x = geometry.centerVector
          .clone()
          .add(
            geometry.lengthVector
              .clone()
              .norm()
              .rotateDeg(firstWireOfPeice)
              .multiply(new Victor(geometry.doubleTopMax, geometry.doubleTopMax))
          );
  
        let adjust;
  
        if (firstWireOfPeice < 55 || firstWireOfPeice > 295) {
          adjust = new Victor(geometry.radius - 10, geometry.radius - 10);
        } else if (firstWireOfPeice > 95 && firstWireOfPeice < 265) {
          adjust = new Victor(geometry.radius + 10, geometry.radius + 10);
        } else {
          adjust = new Victor(geometry.radius, geometry.radius);
        }
  
        const edge = geometry.centerVector
          .clone()
          .add(
            geometry.lengthVector
              .clone()
              .norm()
              .rotateDeg(firstWireOfPeice)
              .multiply(adjust)
          );
  
        drawLabel(labels[pos], edge, x);
      }
    
    function indicateHit(ctx, dart) {

        var size = 5;
        if (dart.type === 'centroid') {
            size = 15
        }
        // Draw the outer circle
        ctx.beginPath();
        ctx.arc(dart.x*geometry.scaleRatio, dart.y*geometry.scaleRatio, size, 0, 2 * Math.PI, false);
        ctx.fillStyle = dart.color;
        ctx.fill();
    
        // Draw the border
        ctx.lineWidth = 1; // Set the line width
        ctx.strokeStyle = '#000'; // Set the border color to black
        ctx.stroke();
    
        // Draw the black dot in the center
        ctx.beginPath();
        ctx.arc(dart.x*geometry.scaleRatio, dart.y*geometry.scaleRatio, 1, 0, 2 * Math.PI, false); // Use a smaller radius for the dot
        ctx.fillStyle = '#000'; // Set the fill color to black
        ctx.fill();
    }
}

export function getScore(x, y) {

    let score = 0;
    const sectors = [
        6, 13, 4, 18, 1, 20, 5, 12, 9, 14, 11, 8, 16, 7, 19, 3, 17, 2, 15, 10
    ];
    const sectorDegrees = 18; // Each sector is 18 degrees (360/20)
    const degrees = getDegrees(x*geometry.scaleRatio, y*geometry.scaleRatio, geometry.center);
    const distanceFromCenter = getDistanceFromCenter(x*geometry.scaleRatio, y*geometry.scaleRatio);
    const sectorIndex = Math.floor(((degrees + 9) % 360) / sectorDegrees); // +9 to offset the first sector being at -9 degrees
    const sectorScore = sectors[sectorIndex];
    let scoreText = '';

    if (distanceFromCenter <= 6 * geometry.scaleRatio) { // Double Bullseye
        score = 50;
        scoreText = 'Double bullseye';
    } else if (distanceFromCenter <= 13 * geometry.scaleRatio) { // Bullseye
        score = 25;
        scoreText = 'Bullseye';
    } else if (distanceFromCenter <= 80 * geometry.scaleRatio && distanceFromCenter >= 70 * geometry.scaleRatio) { // Triple Ring
        score = sectorScore * 3;
        scoreText = 'Triple ' + sectorScore;
    } else if (distanceFromCenter <= 145 * geometry.scaleRatio && distanceFromCenter >= 135 * geometry.scaleRatio) { // Double Ring
        score = sectorScore * 2;
        scoreText = 'Double ' + sectorScore;
    } else if (distanceFromCenter <= 135 * geometry.scaleRatio) { // Any other area within valid scoring range
        score = sectorScore;
        scoreText = 'Single ' + sectorScore;
    } else { // Missed the board
        score = 0;
        scoreText = 'Missed';
    }

    return [score, scoreText];
}

function getDegrees(x, y) {
    // Adjust coordinates so that the center of the canvas is the origin
    const adjustedX = x - geometry.center.x;
    const adjustedY = geometry.center.y - y; // Invert the y-axis

    // Calculate the angle in radians
    const angleRadians = Math.atan2(adjustedY, adjustedX);

    // Convert the angle to degrees
    let angleDegrees = angleRadians * (180 / Math.PI);

    // Ensure the angle is within the range [0, 360)
    if (angleDegrees < 0) {
        angleDegrees += 360;
    }

    return angleDegrees;
}

function getDistanceFromCenter(x, y) {
    const dx = x - geometry.center.x;
    const dy = y - geometry.center.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    return distance;
}