<template>
  <transition name="fade">
    <div v-if="show" class="tooltip" ref="tooltipRef" :style="{ left: `${data.x}px`, top: `${data.y}px` }">
      <button class="close-button" @click="close">X</button>
      <canvas ref="canvasRef" :width="canvasSize" :height="canvasSize"></canvas>
      <p>{{ roundDate }}</p>
      <p>Spread: {{ totalDistance }}</p>
      <p>Score: {{ totalScore }}</p>
      <ul>
        <li v-for="(hitText, index) in hitTexts" :key="index">{{ hitText }}</li>
      </ul>
      <button class="delete-button" @click="confirmDelete">Delete round</button>
    </div>
  </transition>
</template>

<script>
import { ref, watch, nextTick, computed } from 'vue';
import { drawDartBoard } from '../dartboard.js';
import { deleteDocument } from '../stats.js';

export default {
  props: ['show', 'data'],
  methods: {
    close() {
      this.$emit('close');
    },
    async confirmDelete() {
      if (confirm('Are you sure you want to delete this round?')) {
        await deleteDocument(this.data.roundId);
        this.$emit('deleted');
        this.$router.go();
      }
    },
  },
  setup(props) {
    const canvasRef = ref(null);
    const tooltipRef = ref(null);
    const canvasSize = ref(null);
    const offscreenCanvas = document.createElement('canvas');
    const offscreenCtx = offscreenCanvas.getContext('2d');

    const totalScore = computed(() => {
      if (Array.isArray(props.data.hits)) {
        return props.data.hits.reduce((sum, hit) => sum + hit.score, 0);
      }
      return 0;
    });

    const hitTexts = computed(() => {
      if (Array.isArray(props.data.hits)) {
        return props.data.hits.map(hit => hit.scoreText);
      }
      return [];
    });

    const roundNumber = computed(() => {
      if (props.data.hits) {
        return props.data.index + 1;
      }
      return 0;
    });

    const roundDate = computed(() => {
      if (props.data.hits) {
        return props.data.time.toDate().toLocaleString();
      }
      return 0;
    });

    const totalDistance = computed(() => {
      if (Array.isArray(props.data.hits)) {
        return props.data.totalDistance.toFixed(2);
      }
      return 0;
    });

    const draw = async () => {
    const tooltip = tooltipRef.value;
    const canvas = canvasRef.value;
    if (tooltip && canvas) {
      canvasSize.value = 300; //300 to get the right scaling
      canvas.width = canvasSize.value;
      canvas.height = canvasSize.value;
      const ctx = canvas.getContext('2d');
      offscreenCanvas.width = canvasSize.value * 2; // Double the size for high DPI displays
      offscreenCanvas.height = canvasSize.value * 2; // Double the size for high DPI displays
      await drawDartBoard(offscreenCtx, props.data.hits);
      ctx.drawImage(offscreenCanvas, 0, 0, canvasSize.value, canvasSize.value);
    }
  };

    watch(() => props.data, (newVal) => {
      if (newVal) {
        nextTick(() => {
          draw();
        });
      }
    });

  return { roundNumber, roundDate, totalScore, totalDistance, hitTexts, canvasRef, tooltipRef, canvasSize };
  },
};
</script>