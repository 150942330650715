<template>
  <div>
    <canvas class="statBoard" ref="canvasRef"></canvas>
  </div>
</template>

<script>
import { ref, onMounted, computed, inject } from 'vue';
import { drawDartBoard } from '../../dartboard.js'; 
import { getRoundsData } from '../../stats.js';

export default {
  name: 'CentroidChart',
  props: ['numRounds'],
  setup(props) {
    const userId = inject('userId');
    const numRounds = ref(props.numRounds)
    const canvasRef = ref(null);
    const roundsData = ref([]); 
    const offscreenCanvas = document.createElement('canvas');
    const offscreenCtx = offscreenCanvas.getContext('2d');

    const allHits = computed(() => {
      const data = roundsData.value.flatMap(round => round.hits);
      return data
    });

    const centroid = computed(() => {
      const sumX = allHits.value.reduce((sum, hit) => sum + hit.x, 0);
      const sumY = allHits.value.reduce((sum, hit) => sum + hit.y, 0);
      return { x: sumX / allHits.value.length, y: sumY / allHits.value.length };
    });

    const draw = async () => {
    const canvas = canvasRef.value;
    if (canvas) {
      canvas.width = 600;
      canvas.height = 600;
      offscreenCanvas.width = 600;
      offscreenCanvas.height = 600;

      // Create a new array of hits with color grey
      const greyHits = allHits.value.map(hit => ({ ...hit, color: 'grey' }));

      // Add the centroid hit with color fuchsia
      const centroidHit = { ...centroid.value, color: 'fuchsia', type: 'centroid'};
      greyHits.push(centroidHit);

      console.log(centroidHit);
      console.log(allHits.value);

      await drawDartBoard(offscreenCtx, greyHits);
      const ctx = canvas.getContext('2d');
      ctx.drawImage(offscreenCanvas, 0, 0, canvas.width, canvas.height);
    }
  };

    onMounted(async () => {
      roundsData.value = await getRoundsData(userId.value, numRounds.value);
      draw();
    });

    return { canvasRef };
  },
};
</script>