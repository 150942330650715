import { createApp, ref } from 'vue';
import App from './App.vue';
import router from './router';
import { initializeApp, getApps } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const firebaseConfig = JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG);

// Check if a Firebase app is already initialized
let firebaseApp;
if (!getApps().length) {
  firebaseApp = initializeApp(firebaseConfig);
} else {
  firebaseApp = getApps()[0]; // Use the already initialized app
}

export const db = getFirestore(firebaseApp);

let appInstance;
const userId = ref(null);
const isLoading = ref(true);

onAuthStateChanged(getAuth(), (user) => {
  if (user) {
    userId.value = user.uid;
  }

  isLoading.value = false;

  if (!appInstance) {
    appInstance = createApp(App)
      .use(router)
      .provide('userId', userId)
      .provide('isLoading', isLoading)
      .mount('#app');
  }
});