<template>
  <div>
    <canvas class="statBoard" ref="canvasRef"></canvas>
    <div ref="heatmapContainerRef" class="heatmap-container">
      <canvas ref="heatmapCanvasRef" class="heatmap-canvas"></canvas>
    </div>
    <div class="gradient">
      <div class="gradient-bar">
        <span class="label">Low</span>
        <canvas ref="gradientCanvasRef"></canvas>
        <span class="label">High</span>
      </div>
    </div>
    <div class="center-text">Concentration</div>
  </div>
</template>

<script>
import { ref, onMounted, nextTick, inject } from 'vue';
import { drawDartBoard } from '../../dartboard.js'; 
import { getRoundsData } from '../../stats.js';
import h337 from 'heatmap.js';

export default {
  name: 'HeatMapChart',
  props: ['numRounds'],
  setup(props) {
    const userId = inject('userId'); 
    const canvasRef = ref(null);
    const heatmapCanvasRef = ref(null);
    const gradientCanvasRef = ref(null);
    const heatmapContainerRef = ref(null);
    const roundsData = ref([]);
    const numRounds = ref(props.numRounds);
    const dartboardCanvas = document.createElement('canvas');
    const dartboardCtx = dartboardCanvas.getContext('2d');

    const draw = async () => {
      const canvas = canvasRef.value;
      const heatmapCanvas = heatmapCanvasRef.value;
      if (canvas && heatmapCanvas) {
        canvas.width = 600;
        canvas.height = 600;
        dartboardCanvas.width = 600;
        dartboardCanvas.height = 600;
        heatmapCanvas.width = 600;
        heatmapCanvas.height = 600;

        await drawDartBoard(dartboardCtx, []);

        const heatmapContainer = heatmapContainerRef.value;
        if (heatmapContainer) {
          heatmapContainer.style.width = '600px';
          heatmapContainer.style.height = '600px';
        }

        const heatmapInstance = h337.create({
          // container: document.querySelector('body'),
          container: heatmapContainer,
          canvas: heatmapCanvas,
          radius: 30,
          maxOpacity: 1,
          minOpacity: .2,
          blur: .5,
          gradient: {
            .0: 'aqua',
            .5: 'blue',
            .8: 'darkblue',
            1: 'darkviolet'
          }
        });

        const gradientCanvas = gradientCanvasRef.value;
        if (gradientCanvas) {
          gradientCanvas.width = 300;
          gradientCanvas.height = 50;
          const gradientCtx = gradientCanvas.getContext('2d');
          const gradient = gradientCtx.createLinearGradient(0, 0, gradientCanvas.width, 0);
          gradient.addColorStop(0, 'aqua');
          gradient.addColorStop(0.5, 'blue');
          gradient.addColorStop(0.8, 'darkblue');
          gradient.addColorStop(1, 'darkviolet');
          gradientCtx.fillStyle = gradient;
          gradientCtx.fillRect(0, 0, gradientCanvas.width, gradientCanvas.height);
        }

        roundsData.value = await getRoundsData(userId.value, numRounds.value)
        // Flatten the hits arrays from all rounds into a single array
        const allHits = roundsData.value.flatMap(round => round.hits);

        const dartValue = 5;

        // Transform allHits into the format expected by heatmap.js
        const heatmapData = {
          max: 10,
          data: allHits.map(hit => ({
            x: hit.x,
            y: hit.y,
            value: dartValue
          }))
        };

        heatmapInstance.setData(heatmapData);

        await nextTick();

        const ctx = canvas.getContext('2d');
        ctx.drawImage(dartboardCanvas, 0, 0, canvas.width, canvas.height);
        ctx.drawImage(heatmapCanvas, 0, 0, canvas.width, canvas.height);
      }
    };

    onMounted(draw);

    return { canvasRef, heatmapCanvasRef, gradientCanvasRef, heatmapContainerRef };
  },
};
</script>