import { createRouter, createWebHistory } from 'vue-router';
import Dartboard from '../components/Dartboard.vue';
import LoginPage from '../components/LoginPage.vue';
import StatsApp from '../components/StatsApp.vue';
import AboutPage from '../components/AboutPage.vue';

const routes = [
  { path: '/', component: Dartboard },
  { path: '/login', component: LoginPage },
  { path: '/stats', component: StatsApp },
  { path: '/about', component: AboutPage }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;