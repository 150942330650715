import { collection, getDocs, query, orderBy, limitToLast, deleteDoc } from 'firebase/firestore'; 
import { toRaw } from 'vue-demi';
import { db } from './main.js';

export async function getRoundsData(userId, x) {
    let roundsQuery;

    if (x!=="all") {
        roundsQuery = query(
            collection(db, 'users', userId, 'rounds'),
            orderBy('time'),
            limitToLast(x)
        );
    } else {
        roundsQuery = query(
            collection(db, 'users', userId, 'rounds'),
            orderBy('time')
        );
    }
    
    const roundsSnapshot = await getDocs(roundsQuery);
    const data = roundsSnapshot.docs.map(doc => {
        return { ...doc.data(), docRef: doc.ref };
      });
    return data;
    }

export async function deleteDocument(docRef) {
    const rawDocRef = toRaw(docRef);
    await deleteDoc(rawDocRef);
  }

export function mean(data, prop) {
    const total = data.reduce((acc, c) => acc + c[prop], 0);
    return (total / data.length)
}

export function median(data, prop) {
    const numbers = data.map(item => item[prop]);
    const sorted = numbers.sort((a, b) => a - b);
    const middle = Math.floor(sorted.length / 2);

    if (sorted.length % 2 === 0) {
        return ((sorted[middle - 1] + sorted[middle]) / 2)
    } else {
        return sorted[middle]
    }
}
export function formatDate(timestamp) {
    const date = timestamp.toDate();
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`;
  }