<template>
  <div id="app-container">
    <div id="dartboard-box">
      <canvas ref="canvasRef" @click="handleClick"></canvas>
    </div>
    <div id="scores-box">
      <div id="total-score">
        <p>Total score:</p>
        <input id="total-score-box" type="text" readonly>
      </div>
      <div id="total-distance">
        <p>Total dart distance:</p>
        <input id="total-distance-box" type="text" readonly>
      </div>
      <div id="info-box">{{ infoBoxText }}</div>
      <div id="individual-scores">
        <div v-for="(dart, index) in (hits.length < 3 ? hits.concat(Array(3 - hits.length).fill({})) : hits)" :key="index" :style="{ backgroundColor: dart.color || 'transparent' }">
          <div class="score-text">
            {{ dart.score !== undefined ? (dart.score < 10 ? dart.score + '&nbsp;' : dart.score) + ' - ' + dart.scoreText : '' }}</div>
          <button class="remove-button" v-if="dart.score !== undefined" v-html="'&times;'" @click="removeDart(index)"></button>
        </div>
      </div>
      <button ref="newRoundButton" class="new-round-button" :disabled="newRoundButtonDisabled" @click="startNewRound">New Round</button>
      <button ref="registerRoundButton" class="register-round-button" :disabled="registerRoundButtonDisabled" @click="registerRound">Register Round</button>  
    </div>
  </div>
</template>

<script>
import { getScore, drawDartBoard } from '../dartboard.js';
import { ref, onMounted, watch, onUnmounted } from 'vue';
import { collection, doc, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../main.js';

export default {
  name: 'DartBoard',
  setup() {
    // Components and supporting vars
    const canvasRef = ref(null);
    const canvasSize = ref(Math.min(window.innerWidth * 0.8, 600));
    const canvasToMmRatio = ref(600/451);
    const ctx = ref(null);
    const newRoundButtonRef = ref(null);
    const newRoundButtonDisabled = ref(true);
    const registerRoundButtonRef = ref(null);
    const registerRoundButtonDisabled = ref(true);
    const infoBoxText = ref(' ');
    const hits = ref([]);
    const hitColors = ref(['fuchsia', 'deepskyblue', 'darkorange']);
    var totalScore = ref(0);
    var score = ref(0);
    var scoreText = ref('');
    var totalDistance = ref(0)

    watch(canvasSize, (newSize) => {
      canvasRef.value.width = newSize;
      canvasRef.value.height = newSize;

      // Redraw the dartboard with the new size
      draw();
    });

    // Methods

    const handleClick = (event) => {
      const rect = canvasRef.value.getBoundingClientRect();
      const x = event.clientX - rect.left
      const y = event.clientY - rect.top

      if (hits.value.length < 3){
        newRoundButtonDisabled.value = false;
        addDart(x,y);
        infoBoxText.value = '';
      } 
      if (hits.value.length === 3) {
        infoBoxText.value = 'Round over!';
        registerRoundButtonDisabled.value = false;
      }
    };

    const addRoundForUser = async (userId, roundData) => {
      const roundsCollection = collection(db, 'users', userId, 'rounds');
      const newRoundDoc = doc(roundsCollection);
      await setDoc(newRoundDoc, roundData);
    }

    const registerRound = () => {
      const auth = getAuth();
      const userId = auth.currentUser ? auth.currentUser.uid : null;
      if (userId) {
        const roundData = { hits: hits.value, totalDistance: totalDistance.value, totalScore: totalScore.value, time: new Date() };
        addRoundForUser(userId, roundData);
        infoBoxText.value = 'Round added to history.';
        registerRoundButtonDisabled.value = true;
        setTimeout(() => {
          startNewRound();
        }, 2000);
      } else {
        infoBoxText.value = 'Log in to register round.';
      }
    };

    const draw = () => {
      const ctx = canvasRef.value.getContext('2d');
      drawDartBoard(ctx, hits.value);
    };

    const addDart = (x, y) => {

      const scaleRatio = 360 / canvasSize.value;

      // Convert the coordinates back to the canvas coordinate system
      x = x*scaleRatio;
      y = y*scaleRatio;

      [score.value, scoreText.value] = getScore(x, y);

      const color = hitColors.value[0];
      hitColors.value.splice(0, 1);
    
      hits.value.push({ x, y, color, score: score.value, scoreText: scoreText.value });
      draw();
      totalScore.value = totalScore.value + score.value;
      updateTotalScore();
      updateTotalDistance();
    };

    const removeDart = (index) => {
      hitColors.value.unshift(hits.value[index].color);
      totalScore.value = totalScore.value - hits.value[index].score;
      hits.value.splice(index, 1);
      draw();
      updateTotalScore();
      updateTotalDistance();
      infoBoxText.value = '';
      registerRoundButtonDisabled.value = true;
    };

    const updateTotalDistance = () => {
      const totalDistanceBox = document.getElementById('total-distance-box');
      if (hits.value.length === 0) {
          totalDistanceBox.value = '';
        } else {
        totalDistance.value = 0;

        for (let i = 0; i < hits.value.length - 1; i++) {
          const dart1 = hits.value[i];
          const dart2 = hits.value[i + 1];

          const dx = dart2.x - dart1.x;
          const dy = dart2.y - dart1.y;

          const distanceInPixels = Math.sqrt(dx * dx + dy * dy);
          const distanceInMm = distanceInPixels * canvasToMmRatio.value; // Convert the distance to mm

          totalDistance.value += distanceInMm;
        }
      totalDistanceBox.value = totalDistance.value.toFixed(2) + ' mm'; // Display the total distance with 2 decimal places
      }
    };

    const updateTotalScore = () => {
      const totalScoreBox = document.getElementById('total-score-box');
      if (hits.value.length === 0) {
        totalScoreBox.value = '';
      } else {
        totalScoreBox.value = totalScore.value;
      }
    };

    const resetDartboard = () => {
      hits.value = [];
      totalScore.value = 0;
      hitColors.value = ['fuchsia', 'deepskyblue', 'darkorange'];
      updateTotalScore();
      updateTotalDistance();
    };

    const startNewRound = () => {
      
      resetDartboard();
      infoBoxText.value = '';

      newRoundButtonDisabled.value = true;
      registerRoundButtonDisabled.value = true;
      draw();
    };

    onMounted(() => {
      const updateCanvasSize = () => {
        // Get the current zoom level
        const zoomLevel = document.documentElement.clientWidth / window.innerWidth;

        // Adjust the size of your canvas based on the zoom level
        canvasSize.value = Math.min(window.innerWidth * 0.8, 600) * zoomLevel;

        if (canvasRef.value && ctx.value) {
          // Adjust the size of the canvas element
          canvasRef.value.width = canvasSize.value;
          canvasRef.value.height = canvasSize.value;

          // Adjust the scale of your context based on the zoom level
          ctx.value.scale(zoomLevel, zoomLevel);

          // Redraw the dartboard with the new size and scale
          draw();
        }
      };

      window.addEventListener('resize', updateCanvasSize);

      const canvas = canvasRef.value;
      if (canvas) {
        ctx.value = canvas.getContext('2d');
      }

      canvas.width = canvasSize.value;
      canvas.height = canvasSize.value;

      // Start the change input monitor timer
      window.requestAnimationFrame(draw);

      onUnmounted(() => {
        window.removeEventListener('resize', updateCanvasSize);
      });
    });

    return {
      canvasRef,
      newRoundButtonRef,
      newRoundButtonDisabled,
      registerRoundButtonRef,
      registerRoundButtonDisabled,
      hits,
      ctx,
      score,
      scoreText,
      infoBoxText,
      startNewRound,
      registerRound,
      handleClick,
      removeDart
    };
  }
}
</script>