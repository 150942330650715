<template>
  <nav class="navbar">
    <div id="menuToggle" v-if="windowWidth <= 600">
      <i class="fas fa-bars" @click="toggleMenu"></i>
      <div v-if="showMenu" class="dropdown-menu page-menu">
        <div @click="navigate('/')">Play</div>
        <div @click="navigate('/about')">About</div>
        <div @click="navigate('/stats')">Stats</div>
      </div>
    </div>
    <ul v-if="windowWidth > 600">
      <li><a href="/">Play</a></li>
      <li><a href="/about">About</a></li>
      <li><a href="/stats">Stats</a></li>
    </ul>
    <li id="user-section" @click="toggleDropdown">
      <i v-if="user" class="fas fa-check"></i>
      <i v-else class="fas fa-times"></i>
      <i id="user-symbol" class="fas fa-user"></i>
      <div v-if="dropdown" class="dropdown-menu">
        <p>{{ user ? user.email : '' }}</p>
        <a v-if="user" href="#" @click.prevent="logout"><i class="fas fa-sign-out-alt"></i> Logout</a>
        <a v-else href="#" @click.prevent="login"><i class="fas fa-sign-in-alt"></i> Login</a>
      </div>
    </li>
  </nav>
</template>

<script>
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';

export default {
  data() {
    return {
      user: null,
      showMenu: false,
      dropdown: false,
      windowWidth: window.innerWidth
    };
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
      if (this.showMenu) {
        document.addEventListener('click', this.closeMenu);
      }
    },
    closeMenu(event) {
      if (event.target.closest('#menuToggle')) return;
      this.showMenu = false;
      document.removeEventListener('click', this.closeMenu);
    },
    toggleDropdown() {
      this.dropdown = !this.dropdown;
    },
    navigate(path) {
      this.$router.push(path);
      this.showMenu = false;
    },
    async logout() {
      const auth = getAuth();
      await signOut(auth);
      this.user = null;
      alert('You have been logged out.')
    },
    login() {
      this.$router.push('/login');
    },
  },
  created() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      this.user = user;
    });
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeMenu);
  }
}
</script>